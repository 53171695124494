<template>
    <div>
        <!-- 1L -->
        <van-empty image="search" description="请使用微信或支付宝扫码支付！" v-if="checkSource === false"/>
        <!-- 2L -->
        <van-empty image="network" v-if="checkSource === true">
            <template #description>
                {{ description }}
            </template>
        </van-empty>
    </div>
</template>

<script>

    import {Empty} from 'vant';
    import {getAliCodeUrl, getWxCodeUrl} from "../../api/base";
    import {getSN, isEmpty} from "../../utils/common";

    export default {
        name: "code",
        components: {
            [Empty.name]: Empty,
        },
        data() {
            return {
                description: '请使用微信或支付宝扫码支付！',
                checkSource: false,
                deviceSn: null
            };
        }

        , mounted() {
            // this.$router.push({name: 'info'});
            this.checkSn();
            this.checkMobileSource();
        }

        , methods: {

            checkSn() {
                //
                let sn = getSN();
                //
                if (isEmpty(sn)) {
                    this.checkSource = true;
                    this.description = '无法识别设备号，请重试！';
                    return;
                }
                //
                this.deviceSn = sn;
                sessionStorage.setItem('DEVICE_SN', sn);
            }

            , checkMobileSource() {
                //
                if (this.checkSource) {
                    return;
                }
                //
                if (this.$client.WEIXIN) {
                    window.location.href = getWxCodeUrl(this.deviceSn);
                    this.description = '微信授权中...';
                    this.checkSource = true;
                } else if (this.$client.ALIPAY) {
                    window.location.href = getAliCodeUrl(this.deviceSn);
                    this.description = '支付宝授权中...';
                    this.checkSource = true;

                }
            }

        }
    }
</script>

<style scoped>
</style>
